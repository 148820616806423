import axios from 'axios'
import {BASE_API_URL} from '../config'

const req = axios.create({
  baseURL: BASE_API_URL,
})

const catchApiError = (err) => {
  let apiErrorMessage =
    err.response && err.response.data && err.response.data.message
  if (apiErrorMessage instanceof Object) {
    const errorKeys = Object.keys(apiErrorMessage)
    if (errorKeys.length) {
      const errorObject = apiErrorMessage
      apiErrorMessage = ''
      for (const key of errorKeys) {
        apiErrorMessage += errorObject[key] + '\n'
      }
    }
  }
  throw apiErrorMessage ? new Error(apiErrorMessage) : err
}

const addAuthToken = (token) => {
  req.defaults.headers.common['Authorization'] = token
}

const removeAuthToken = (token) => {
  delete req.defaults.headers.common['Authorization']
}

export {req, catchApiError, addAuthToken, removeAuthToken}
