import {req, catchApiError} from './index'

/**
 *
 * @param {{
 *  username: string,
 *  password: string
 * }} body
 */
export const login = async (body) => {
  const res = await req({
    method: 'POST',
    url: '/auth/signin',
    data: body,
  }).catch(catchApiError)
  return res.data
}

/**
 *
 * @param {string} token
 * @returns
 */
export const refreshToken = async (token) => {
  const res = await req({
    headers: {Authorization: token},
    method: 'POST',
    url: '/auth/refresh',
  }).catch(catchApiError)
  return res.data
}

/**
 *
 * @param {string} token
 * @return
 */
export const sendMailForgotPassword = async (body) => {
  const res = await req({
    method: 'POST',
    url: '/auth/send-mail-forgot-password',
    data: body,
  }).catch(catchApiError)
  return res.status
}

/**
 * @param {{newPassword: string}} body
 * @returns
 */
export const resetPassword = async (body) => {
  const res = await req({
    method: 'POST',
    url: '/auth/reset-password',
    data: body,
  }).catch(catchApiError)
  return res.status
}
