import * as XLSX from 'xlsx'
export const A_YEAR = 365 * 24 * 60 * 60 * 1000

export const genClass = (...cls) => {
  const classNames = cls.length > 1 ? cls : cls[0]
  if (!classNames) return ''
  if (typeof classNames === 'string') {
    return classNames
  }
  if (classNames instanceof Array) {
    return classNames.map((c) => genClass(c)).join(' ')
  }
  if (classNames instanceof Object) {
    return genClass(Object.keys(classNames).filter((key) => !!classNames[key]))
  }
}

export const wait = (mili, data) => {
  return new Promise((resolve) => setTimeout(() => resolve(data), mili))
}

export const clamp = (value, min, max) => {
  return value < min ? min : value > max ? max : value
}

export const genId = () => {
  return btoa(Date.now() + Math.random().toString(36).substr(2, 9))
}

export const toLocaleDate = (date) => {
  const d = new Date()
  return d.getTime() ? d.toLocaleDateString() : ''
}

export const toLocaleTime = (date) => {
  const d = new Date()
  return d.getTime() ? d.toLocaleTimeString() : ''
}

export const toLocaleDatetime = (date) => {
  const d = new Date()
  return d.getTime() ? d.toLocaleString() : ''
}

export const toAge = (date) => {
  const d = new Date(date)
  return d.getTime() ? Math.floor((Date.now() - d) / A_YEAR) : ''
}

export const to4Digit = (number) => {
  const num = parseInt(number) || 0
  return num < 1000
    ? `0${to3Digit(number)}`
    : num < 10000
    ? `${num}`
    : to4Digit(number % 10000)
}

export const to3Digit = (number) => {
  const num = parseInt(number) || 0
  return num < 100
    ? `0${to2Digit(number)}`
    : num < 1000
    ? `${num}`
    : to3Digit(number % 1000)
}

export const to2Digit = (number) => {
  const num = parseInt(number) || 0
  return num < 10 ? `0${num}` : num < 100 ? `${num}` : to2Digit(number % 100)
}

const convertToJson = (csv) => {
  const lines = csv.split('\n')

  let result = []

  const headers = lines[0].split(',')

  for (let i = 1; i < lines.length; i++) {
    let obj = {}
    const currentline = lines[i].split(',')

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j]
    }

    result.push(obj)
  }

  return result
}

export const handleDataExcel = (target) => {
  return new Promise((resolve, reject) => {
    const f = target.files[0]
    const reader = new FileReader()

    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, {type: 'binary'})
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_csv(ws, {header: 1})
      const register = convertToJson(data)
      register.pop()
      const body = []
      for (const user of register) {
        const {가상번호, 착신번호, 상점명} = user
        body.push({
          number0504: 가상번호,
          phoneNumber: replaceAll(착신번호, '-', ''),
          companyName: 상점명,
        })
      }
      const handleData = []
      for (let i = 0; i < body.length; i++) {
        const {phoneNumber} = body[i]
        if (phoneNumber !== '') handleData.push(body[i])
      }
      resolve(handleData)
    }
    reader.onerror = reject
    reader.readAsBinaryString(f)
  })
}

export const toFomartPhoneNumber = (number) => {
  return `${number.slice(0, 3)}-${number.slice(3, 7)}-${number.slice(7)}`
}

export const replaceTime = (date) => {
  return date.replace('T', ' ').slice(0, 19)
}

export const changeTimeZone = (date) => {
  const now = new Date(date)
  const timeZone = new Date(now.getTime() - now.getTimezoneOffset() * 60 * 1000)
  return timeZone.toISOString()
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace)
}
