import styled from 'styled-components'
import BBtn from '../../components/base/Btn'
import BIcon from '../../components/base/Icon'
import {genClass} from '../../shared/utils'

const StlModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(30, 30, 30, 0.1);
  width: 100vw;
  height: 100vh;

  &.modal-container-empty {
    display: none;
  }
`

const ModalContainer = ({
  messageList = [],
  confirmMessage,
  hideMessage,
  clearMessage,
  hideConfirm,
}) => {
  if (!hideMessage)
    throw new Error('ModalContainer props missing handler "hideMessage"')
  if (!clearMessage)
    throw new Error('ModalContainer props missing handler "clearMessage"')
  if (!hideConfirm)
    throw new Error('ModalContainer props missing handler "hideConfirm"')

  const isEmpty = !messageList.length && !confirmMessage

  return (
    <StlModalContainer
      className={genClass('modal-container', {
        'modal-container-empty': isEmpty,
      })}
      onClick={() => clearMessage()}
    >
      {messageList.map((message, i) => {
        return (
          <MessageCard
            key={message.id}
            {...message}
            hideMessage={hideMessage}
          />
        )
      })}
      {confirmMessage ? (
        <ConfirmMessageCard
          key='confirm-message'
          {...confirmMessage}
          hideConfirm={hideConfirm}
        ></ConfirmMessageCard>
      ) : (
        ''
      )}
    </StlModalContainer>
  )
}

const MESSAGE_COLORS = ['primary', 'red']

const StlMessageCard = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
  min-width: 500px;
  max-width: 800px;
  margin: 10px;
  border: 1px solid #eaeef5;
  border-radius: 4px;
  position: relative;
  overflow-wrap: break-word;
  & .mc-title {
    white-space: pre-wrap;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    padding: 17px 17px 10px 17px;
    background: #f6f6f6;
    color: #0065f2;
    border-bottom: 1px solid #eaeef5;
  }
  & .mc-text {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    white-space: pre-wrap;
  }
  & .mc-btn-close {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 15px;
    height: 15px;
    color: #d2d2d2;
  }
  & .mc-actions-bar {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    background: #f6f6f6;
    border-top: 1px solid #eaeef5;
    & > * {
      margin-left: 10px;
    }
  }
`

const MessageCard = ({
  id = '',
  title,
  text,
  btnText = 'Cancel',
  color,
  hideMessage,
}) => {
  return (
    <StlMessageCard
      className={genClass('message-card', {
        [`mc-color-${color}`]: MESSAGE_COLORS.includes(color),
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <BIcon
        className='mc-btn-close'
        icon='x'
        onClick={() => hideMessage(id)}
      />
      <div className='mc-title'>{title}</div>
      <div className='mc-text'>{text}</div>
      <div className='mc-actions-bar'>
        <BBtn size='sm' color='gray' onClick={() => hideMessage(id)}>
          {btnText}
        </BBtn>
      </div>
    </StlMessageCard>
  )
}

const ConfirmMessageCard = ({
  title,
  text,
  btnConfirmText = 'Yes',
  btnCancelText = 'Cancel',
  hideConfirm,
  color,
}) => {
  return (
    <StlMessageCard
      className={genClass('message-card', {
        [`mc-color-${color}`]: MESSAGE_COLORS.includes(color),
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <BIcon className='mc-btn-close' icon='x' onClick={() => hideConfirm()} />
      <div className='mc-title'>{title}</div>
      <div className='mc-text'>{text}</div>
      <div className='mc-actions-bar'>
        <BBtn size='sm' onClick={() => hideConfirm(true)}>
          {btnConfirmText}
        </BBtn>
        <BBtn size='sm' color='gray' onClick={() => hideConfirm()}>
          {btnCancelText}
        </BBtn>
      </div>
    </StlMessageCard>
  )
}

export default ModalContainer
