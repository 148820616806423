import React, {useContext} from 'react'
import Routes from './routes'
import {wrapProviders} from './store'
import {AuthProvider} from './store/auth'
import {NotiContainer, NotiContext, NotiProvider} from './store/noti'
import {ModalContainer, ModalContext, ModalProvider} from './store/modal'

const FixedCpnsWrap = () => {
  const {
    state: {notiList},
    hideNoti,
    clearNoti,
  } = useContext(NotiContext)
  const {
    state: {messageList, confirmMessage},
    hideMessage,
    clearMessage,
    hideConfirm,
  } = useContext(ModalContext)

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 2,
      }}
    >
      <NotiContainer
        notiList={notiList}
        hideNoti={hideNoti}
        clearNoti={clearNoti}
      />
      <ModalContainer
        messageList={messageList}
        hideMessage={hideMessage}
        clearMessage={clearMessage}
        confirmMessage={confirmMessage}
        hideConfirm={hideConfirm}
      />
    </div>
  )
}

const App = () => {
  return (
    <React.StrictMode>
      <FixedCpnsWrap />
      <Routes />
    </React.StrictMode>
  )
}

export default wrapProviders(App, AuthProvider, NotiProvider, ModalProvider)
