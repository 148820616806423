import createStore from '../createStore'
import ModalContainer from './ModalContainer'
import {genId} from '../../shared/utils'

// use to create async confirm
const currentConfirmPromise = {
  resolve: null,
}

/**
 * @type {{
 *  messageList: [{
 *    id: '',
 *    title: '',
 *    text: '',
 *    btnText: '',
 *    color: ''
 *  }],
 *  confirmMessage: null | {
 *    title: '',
 *    text: '',
 *    btnConfirmText: '',
 *    btnCancelText: '',
 *    color: ''
 *  }
 * }}
 */
const initialValue = {
  messageList: [],
  confirmMessage: null,
}

const ACTIONS = {
  PUSH_MESSAGE: 'PUSH_MESSAGE',
  HIDE_MESSAGE: 'HIDE_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  PUSH_CONFIRM: 'PUSH_CONFIRM',
  HIDE_CONFIRM: 'HIDE_CONFIRM',
}

/**
 *
 * @param {initialValue} state
 * @param {{
 *  type: '',
 *  payload: {}
 * }}} action
 */
const reducer = (state, {type, payload}) => {
  switch (type) {
    case ACTIONS.PUSH_MESSAGE: {
      const {messageList} = state
      return {
        ...state,
        messageList: [...messageList, formatMessagePayload(payload)],
      }
    }
    case ACTIONS.HIDE_MESSAGE: {
      const {messageList} = state
      return {
        ...state,
        messageList: [...messageList.filter((mess) => mess.id !== payload)],
      }
    }
    case ACTIONS.CLEAR_MESSAGE: {
      return {
        ...state,
        messageList: [],
      }
    }
    case ACTIONS.PUSH_CONFIRM: {
      return {
        ...state,
        confirmMessage: payload,
      }
    }
    case ACTIONS.HIDE_CONFIRM: {
      return {
        ...state,
        confirmMessage: null,
      }
    }
    default:
      return state
  }
}

const actions = {
  pushMessage: (dispatch) => (messageData) => {
    return dispatch({
      type: ACTIONS.PUSH_MESSAGE,
      payload: messageData,
    })
  },
  hideMessage: (dispatch) => (messageId) => {
    return dispatch({
      type: ACTIONS.HIDE_MESSAGE,
      payload: messageId,
    })
  },
  clearMessage: (dispatch) => () => {
    return dispatch({
      type: ACTIONS.CLEAR_MESSAGE,
    })
  },
  pushConfirm: (dispatch) => (confirmData) => {
    dispatch({
      type: ACTIONS.PUSH_CONFIRM,
      payload: confirmData,
    })
    return new Promise((resolve) => {
      currentConfirmPromise.resolve = resolve
    })
  },
  hideConfirm: (dispatch) => (confirmed) => {
    dispatch({
      type: ACTIONS.HIDE_CONFIRM,
    })
    currentConfirmPromise.resolve && currentConfirmPromise.resolve(!!confirmed)
    currentConfirmPromise.resolve = null
  },
}

export {ModalContainer}
export const {Provider: ModalProvider, Context: ModalContext} = createStore(
  reducer,
  actions,
  initialValue
)

function formatMessagePayload(messageData) {
  return {
    ...messageData,
    id: genId(),
  }
}
