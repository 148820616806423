import {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {genClass} from '../../shared/utils'
import BIcon from '../../components/base/Icon'

const StlNotiContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  &.noti-container-empty {
    display: none;
  }
`

const NotiContainer = ({notiList = [], hideNoti}) => {
  if (!hideNoti)
    throw new Error('NotiContainer props missing handler "hideNoti"')

  const isEmpty = !notiList.length

  return (
    <StlNotiContainer
      className={genClass('noti-container', {
        'noti-container-empty': isEmpty,
      })}
    >
      {notiList.map((noti, i) => {
        return <NotiCard key={noti.id} {...noti} hideNoti={hideNoti} />
      })}
    </StlNotiContainer>
  )
}

const NOTI_COLORS = ['primary']
const StlNotiCard = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
  min-width: 200px;
  max-width: 400px;
  margin: 10px;
  border: 1px solid #eaeef5;
  border-radius: 4px;
  position: relative;
  overflow-wrap: break-word;
  & .nc-title {
    white-space: pre-wrap;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    padding: 17px 17px 10px 10px;
    background: #f6f6f6;
    color: #0065f2;
    border-bottom: 1px solid #eaeef5;
  }
  & .nc-text {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
  }
  & .nc-btn-close {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 15px;
    height: 15px;
    color: #d2d2d2;
  }
`

const NotiCard = ({
  id = '',
  title = '',
  text = '',
  color = '',
  timeout,
  hideNoti,
}) => {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        mounted.current && hideNoti(id)
      }, timeout)
    }
  }, [])

  return (
    <StlNotiCard
      className={genClass('noti-card', {
        [`nc-color-${color}`]: NOTI_COLORS.includes(color),
      })}
    >
      <BIcon className='nc-btn-close' icon='x' onClick={() => hideNoti(id)} />
      <div className='nc-title'>{title}</div>
      <div className='nc-text'>{text}</div>
    </StlNotiCard>
  )
}

export default NotiContainer
