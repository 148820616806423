import styled from 'styled-components'
import {genClass} from '../../../../shared/utils'

const StlLogo = styled.div``

const AppLogo = ({className, children}) => {
  return (
    <StlLogo className={genClass(className, 'app-logo')}>{children}</StlLogo>
  )
}

export default AppLogo
