import createStore from '../createStore'
import NotiContainer from './NotiContainer'
import {genId} from '../../shared/utils'

const DEFAULT_NOTI_TIMEOUT = 3000

/**
 * @type {{
 *  notiList: [{
 *    id: '',
 *    title: '',
 *    text: '',
 *    color: '',
 *    timeout: 0
 *  }]
 * }}
 */
const initialValue = {
  notiList: [],
}

const ACTIONS = {
  PUSH_NOTI: 'PUSH_NOTI',
  HIDE_NOTI: 'HIDE_NOTI',
  CLEAR_NOTI: 'CLEAR_NOTI',
}

/**
 *
 * @param {initialValue} state
 * @param {{
 *  type: '',
 *  payload: {}
 * }}} action
 */
const reducer = (state, {type, payload}) => {
  switch (type) {
    case ACTIONS.PUSH_NOTI: {
      const {notiList} = state
      return {
        ...state,
        notiList: [...notiList, formatNotiPayload(payload)],
      }
    }
    case ACTIONS.HIDE_NOTI: {
      const {notiList} = state
      return {
        ...state,
        notiList: [...notiList.filter((noti) => noti.id !== payload)],
      }
    }
    case ACTIONS.CLEAR_NOTI: {
      return {
        ...state,
        notiList: [],
      }
    }
    default:
      return state
  }
}

const actions = {
  pushNoti: (dispatch) => (notiData) => {
    return dispatch({
      type: ACTIONS.PUSH_NOTI,
      payload: notiData,
    })
  },
  hideNoti: (dispatch) => (notiId) => {
    return dispatch({
      type: ACTIONS.HIDE_NOTI,
      payload: notiId,
    })
  },
  clearNoti: (dispatch) => () => {
    return dispatch({
      type: ACTIONS.CLEAR_NOTI,
    })
  },
}

export {NotiContainer}
export const {Provider: NotiProvider, Context: NotiContext} = createStore(
  reducer,
  actions,
  initialValue
)

function formatNotiPayload(notiData) {
  const {timeout} = notiData
  return {
    ...notiData,
    timeout: timeout === undefined ? DEFAULT_NOTI_TIMEOUT : timeout,
    id: genId(),
  }
}
