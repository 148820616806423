export default function SearchPlus(props) {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.7 18.3L16 14.6C17.2 13.1 18 11.1 18 9C18 4 14 0 9 0C4 0 0 4 0 9C0 14 4 18 9 18C11.1 18 13.1 17.3 14.6 16L18.3 19.7C18.5 19.9 18.8 20 19 20C19.2 20 19.5 19.9 19.7 19.7C20.1 19.3 20.1 18.7 19.7 18.3ZM2 9C2 5.1 5.1 2 9 2C12.9 2 16 5.1 16 9C16 10.9 15.2 12.7 14 13.9C12.7 15.2 11 15.9 9.1 15.9C5.1 16 2 12.9 2 9ZM12 10C12.6 10 13 9.6 13 9C13 8.4 12.6 8 12 8H10V6C10 5.4 9.6 5 9 5C8.4 5 8 5.4 8 6V8H6C5.4 8 5 8.4 5 9C5 9.6 5.4 10 6 10H8V12C8 12.6 8.4 13 9 13C9.6 13 10 12.6 10 12V10H12Z'
        fill='currentColor'
      />
    </svg>
  )
}
